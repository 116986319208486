import { useState } from "react";
import { useAtom } from "jotai";
import isFloat from 'validator/es/lib/isFloat';
import isInt from 'validator/es/lib/isInt';

import * as state from "../../../state";
import { ORDER_ACTION, WEBSOCKET_ACTION } from "../../../constants";

function EnterTrade({ websocket }) {
  const [token] = useAtom(state.token);

  const [tournament] = useAtom(state.tournament);
  const [competitor] = useAtom(state.competitor);
  const [positions] = useAtom(state.positions);
  const [outstandings] = useAtom(state.outstandings);
  const [sequences] = useAtom(state.sequences);

  const [type, setType] = useState("limit");
  const [price, setPrice] = useState("0");
  const [quantity, setQuantity] = useState("0");
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (action) => {
    if (!competitor) return;

    setErrorText("");
    setSubmitted(true);

    if (type == "limit" && !isFloat(price, { gt: 0, lt: 100 }) || !isInt((price / 0.5).toString())) {
      setErrorText(`Invalid price ${price}`);
      setSubmitted(false);
      return;
    }

    if (!isInt(quantity, { gt: 0, lt: 100 })) {
      setErrorText(`Invalid quantity ${quantity}`);
      setSubmitted(false);
      return;
    }

    const p = !!positions && competitor.id.substring(28) in positions ? Object.values(positions[competitor.id.substring(28)]).reduce((a, b) => a + b, 0) : 0
    const bids = !!outstandings && competitor.id.substring(28) in outstandings ? Object.values(outstandings[competitor.id.substring(28)]).reduce((a, b) => b > 0 ? a + b : a, 0) : 0
    const asks = !!outstandings && competitor.id.substring(28) in outstandings ? Object.values(outstandings[competitor.id.substring(28)]).reduce((a, b) => b < 0 ? a + b : a, 0) : 0
    const q = parseInt(quantity, 10);

    if (action == ORDER_ACTION.BID && tournament.position_limit * 2 - (p + bids + q) < 0) {
      setErrorText(`Quantity exceeds allowed order limit of ${tournament.position_limit * 2 }`);
      setSubmitted(false);
      return;
    }

    if (action == ORDER_ACTION.ASK && tournament.position_limit * 2 + (p + asks - q) < 0) {
      setErrorText(`Quantity exceeds allowed order limit of ${tournament.position_limit * 2  * -1}`);
      setSubmitted(false);
      return;
    }

    websocket.send(JSON.stringify({
      "token": token,
      "action": WEBSOCKET_ACTION.ORDER,
      "data": {
        "tournament": tournament.id,
        "competitor": competitor.id,
        "action": action,
        "sequence": competitor.id.substring(28) in sequences ? sequences[competitor.id.substring(28)] : 1,
        "price": type == "limit" ? parseFloat(price) : null,
        "quantity": parseInt(quantity, 10)
      }
    }));

    setSubmitted(false);
    setPrice(0);
    setQuantity(0);
  }

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6">
      <p className="text-body-lg-m pb-3 text-left">Trade</p>
      <form>
        <div className="flex gap-[2px]">
          <div className="grid grow">
            <label className="input-label" htmlFor="quantity">
              <p className="text-body-xs text-greytext">Team/Player</p>
              <p className="text-body-xs mt-[10px]">{!!competitor ? competitor.name : ""}</p>
            </label>
          </div>
          <div className="grid grow">
            <label className="input-label" htmlFor="team">
              <p className="text-body-xs text-greytext">Order Type</p>
              <select id="team" value={type} onChange={e => setType(e.target.value)} className="input-small rounded-tr-none rounded-br-none">
                <option value="limit">Limit</option>
                <option value="market">Market</option>
              </select>
            </label>
          </div>
          {type === "limit" && (
            <div className="grid w-[56px]">
              <label className="input-label" htmlFor="price">
                <p className="text-body-xs text-greytext">Price</p>
                <input
                  id="price"
                  name="price"
                  type="text"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                  className="input-small rounded-none"
                />
              </label>
            </div>
          )}
          <div className="grid w-[56px]">
            <label className="input-label" htmlFor="quantity">
              <p className="text-body-xs text-greytext">Quantity</p>
              <input
                id="quantity"
                name="quantity"
                type="text"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
              className="input-small rounded-tl-none rounded-bl-none"
              />
            </label>
          </div>
        </div>
        {!!errorText && <div className="text-body-s-medium mt-6 text-lightred">{errorText}</div>}
        <div className="grid grid-cols-2 gap-3">
          {!!tournament && tournament.market_open && !!competitor && competitor.settled === null ? (
            <button className="btn btn-small mt-5" type="button" disabled={submitted} onClick={() => handleSubmit(ORDER_ACTION.BID)}>Buy</button>
            ) : (
            <button className="btn btn-small mt-5" type="button" disabled={true}>Buy</button>
          )}
          {!!tournament && tournament.market_open && !!competitor && competitor.settled === null ? (
            <button className="btn btn-small btn-red mt-5" type="button" disabled={submitted} onClick={() => handleSubmit(ORDER_ACTION.ASK)}>Sell</button>
            ) : (
            <button className="btn btn-small btn-red mt-5" type="button" disabled={true}>Sell</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default EnterTrade;

